@import url(https://fonts.googleapis.com/css?family=Roboto);
html, body, #root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: black;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  background-image: url(../../static/media/gdn-image.7a2ba9c1.svg);
  background-repeat: no-repeat;
  background-position: center 3.5%; 
}

form {
  width: 100%;
  display: grid;
  justify-content: center;
}

.loginBox {
  width: 50%;
  text-align: center;
  position: relative;
  left: 50%;
  -webkit-transform: translate(-50%, 20%);
          transform: translate(-50%, 20%);
  padding: 20px;
  background-color: white;
  box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.4);
}

.img-logo {
  margin: 0 auto;
  width: 40%;
}

.signUpSubmit {
  margin: 25px 0 15px 0;
}

.pwStrRow {
  width: 63%;
  text-align: left;
  margin: auto;
  padding: 0;
  font-size: 12px;
}

.pwStrWeak {
  display: inline-block;
  position: relative;
  left: 0;
  padding: 0;
  margin: 0;
  color: gray;
}

.pwStrStrong {
  display: inline-block;
  position: relative;
  left: 77%;
  padding: 0;
  margin: 0;
  color: gray;
}

@media screen and (max-width: 550px) {
  .loginBox {
    width: 85%;
  }
}



