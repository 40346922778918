form {
  width: 100%;
  display: grid;
  justify-content: center;
}

.loginBox {
  width: 50%;
  text-align: center;
  position: relative;
  left: 50%;
  transform: translate(-50%, 20%);
  padding: 20px;
  background-color: white;
  box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.4);
}

.img-logo {
  margin: 0 auto;
  width: 40%;
}

.signUpSubmit {
  margin: 25px 0 15px 0;
}

.pwStrRow {
  width: 63%;
  text-align: left;
  margin: auto;
  padding: 0;
  font-size: 12px;
}

.pwStrWeak {
  display: inline-block;
  position: relative;
  left: 0;
  padding: 0;
  margin: 0;
  color: gray;
}

.pwStrStrong {
  display: inline-block;
  position: relative;
  left: 77%;
  padding: 0;
  margin: 0;
  color: gray;
}

@media screen and (max-width: 550px) {
  .loginBox {
    width: 85%;
  }
}


